














































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import DangerRateFilter from '@/components/BasicInformation/Tools/ToolFilterComponents/DangerRateFilter.vue';
import StatusFilter from '@/components/BasicInformation/Tools/ToolFilterComponents/StatusFilter.vue';
import { ToolFilterOptions } from '@/store/modules/tool-filter-storage';
import { i18n } from '@/i18n';

interface FilterSubMenu {
  name: string | TranslateResult;
  componentName: string;
}

const filterRows: FilterSubMenu[] = [
  {
    name: i18n.t('basic-information.tool.danger-rate'),
    componentName: 'DangerRateFilter',
  },
  {
    name: i18n.t('basic-information.tool.condition'),
    componentName: 'StatusFilter',
  },
];

export default Vue.extend({
  name: 'ToolFilter',
  components: {
    DangerRateFilter,
    StatusFilter,
  },
  data() {
    return {
      mainActive: false,
      filter: '/images/filter.svg',
      activeComponent: {
        name: '',
        componentName: '',
      },
      filterMenus: filterRows,
    };
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filterStorage/toolFilterStorage/getToolFilterOptions',
      isFiltering: 'filterStorage/toolFilterStorage/isFiltering',
    }),
  },
  methods: {
    openMain() {
      this.mainActive = true;
    },
    closeMain() {
      this.mainActive = false;
    },
    openSubmenu(newComponent: { name: string; componentName: string }) {
      this.activeComponent = newComponent;
      this.mainActive = false;
    },
    closeSubmenu() {
      this.mainActive = true;
      this.activeComponent = { name: '', componentName: '' };
    },
    resetFilter() {
      this.$store.commit('filterStorage/toolFilterStorage/clearToolFilterOptions');
      this.loadFiltered();
    },
    isModified(componentName: string): boolean {
      let isFilterSet = false;
      const filterOptions: ToolFilterOptions = this.filterOptions;

      switch (componentName) {
        case 'DangerRateFilter':
          isFilterSet = filterOptions.dangerRates.length > 0;
          break;
        case 'StatusFilter':
          isFilterSet = filterOptions.active !== undefined;
          break;
      }
      return isFilterSet;
    },
    loadFiltered() {
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('toolStorage/getCategorizedTools', this.$route.params?.id)
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});
