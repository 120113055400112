
















































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import ToolDetails from '@/components/BasicInformation/Tools/Details.vue';
import ToolDocuments from '@/components/BasicInformation/Tools/Documents.vue';
import DeleteTool from '@/components/BasicInformation/Tools/DeleteTool.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import TextField from '@/components/InputField/TextField.vue';
import { menus } from '@/constants/menu/tool-menus';
import { required, maxLength } from 'vuelidate/lib/validators';
import Tool from '@/model/tool';

export default Vue.extend({
  name: 'ToolDetailedView',
  components: {
    DetailedViewBase,
    ActionButton,
    ToolDetails,
    ToolDocuments,
    DeleteTool,
    LoadingIndicator,
    Navigation,
    TextField,
  },
  props: {
    tool: { type: Object as () => Tool },
  },
  data() {
    return {
      menus,
      opened: false,
      editing: false,
      saving: false,
      submitted: false,
      activeComponent: 'ToolDetails',
      title: this.tool.name,
    };
  },
  computed: {
    isDetailsPage(): boolean {
      return this.activeComponent === 'ToolDetails';
    },
  },
  validations: {
    title: {
      required,
      maxLength: maxLength(200),
    },
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.resetEdition();
      this.$emit('close');
    },
    edit() {
      (this as any).loadCategories();
      (this.$refs.details as any).reset();
      this.editing = true;
    },
    save() {
      this.submitted = true;
      (this.$refs.details as any).save();
    },
    resetEdition(modifiedName?: string) {
      this.editing = false;
      this.title = modifiedName ? modifiedName : this.tool.name;
      if (this.isDetailsPage) {
        (this.$refs.details as any).reset();
      }
    },
    setSaving(value: boolean) {
      this.saving = value;
      if (!value) {
        this.submitted = false;
      }
    },
    update(modifiedTool: Tool) {
      this.resetEdition(modifiedTool.name);
      this.$emit('update', modifiedTool);
    },
  },
});
