var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex row m-0"},[_c('div',{staticClass:"col-6 p-0 pr-5"},[_c('FormField',{attrs:{"title":_vm.$t('basic-information.tool.id')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('TextField',{attrs:{"outlined":"","disabled":_vm.saving},model:{value:(_vm.$v.form.identifier.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.identifier, "$model", $$v)},expression:"$v.form.identifier.$model"}}):_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.tool.identifier)+" ")])]},proxy:true}])}),_c('FormField',{attrs:{"title":_vm.$t('basic-information.tool.location-code')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('TextField',{attrs:{"type":"text","errorMessage":_vm.locationCodeError(_vm.submitted, _vm.$v.form.locationCode),"outlined":"","disabled":_vm.loading},model:{value:(_vm.$v.form.locationCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.locationCode, "$model", $$v)},expression:"$v.form.locationCode.$model"}}):_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.tool.locationCode)+" ")])]},proxy:true}])}),_c('FormField',{attrs:{"title":_vm.$t('basic-information.tool.category')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('Select',{attrs:{"items":_vm.categoryOptions(_vm.categories),"outlined":"","clearable":""},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,2648401638),model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}}):_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.tool.category ? _vm.tool.category.name : '-')+" ")])]},proxy:true}])})],1),_c('div',{staticClass:"col-6 p-0 pl-5"},[_c('FormField',{attrs:{"title":_vm.$t('basic-information.tool.danger-rate')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('Select',{attrs:{"items":_vm.dangerRates,"errorMessage":_vm.dangerRateError(_vm.submitted, _vm.$v.form.dangerRate),"outlined":""},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('basic-information.tool.danger-rate-' + item.toLowerCase()))+" ")]}},{key:"selected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('basic-information.tool.danger-rate-' + item.toLowerCase()))+" ")]}}],null,false,3592360134),model:{value:(_vm.$v.form.dangerRate.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.dangerRate, "$model", $$v)},expression:"$v.form.dangerRate.$model"}}):_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.$t('basic-information.tool.danger-rate-' + _vm.tool.dangerRate.toLowerCase()))+" ")])]},proxy:true}])}),_c('FormField',{attrs:{"title":_vm.$t('basic-information.tool.condition')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('div',{staticClass:"d-flex row justify-space-between my-0 mx-3"},[_c('Checkbox',{staticClass:"checkbox-container pl-0",attrs:{"disabled":_vm.loading},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}}),_c('span',[_vm._v(" "+_vm._s(_vm.form.active ? _vm.$t('basic-information.tool.in-use') : _vm.$t('basic-information.tool.not-in-use'))+" ")])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"boolean-icon",class:{ colored: _vm.tool.active }},[_vm._v(" "+_vm._s(_vm.tool.active ? 'fas fa-check' : 'fas fa-times')+" ")]),_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.tool.active ? _vm.$t('basic-information.tool.in-use') : _vm.$t('basic-information.tool.not-in-use'))+" ")])],1)]},proxy:true}])})],1)]),_c('FormField',{staticClass:"col-12 p-0",attrs:{"title":_vm.$t('basic-information.tool.comment')},scopedSlots:_vm._u([{key:"field",fn:function(){return [(_vm.editing)?_c('Textarea',{attrs:{"outlined":"","disabled":_vm.saving,"errorMessage":_vm.commentError(_vm.submitted, _vm.$v.form.comment)},model:{value:(_vm.$v.form.comment.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.comment, "$model", $$v)},expression:"$v.form.comment.$model"}}):_c('span',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.tool.comment)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }