





















import Vue from 'vue';
import { i18n } from '@/i18n';

export default Vue.extend({
  name: 'SearchBar',
  props: {
    searchText: { type: String, default: '' },
    placeholder: { type: String, default: i18n.t('dashboard.search') },
    hasPrependIcon: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  watch: {
    searchText() {
      this.text = this.searchText;
    },
  },
  data() {
    return {
      text: this.searchText,
      searchIcon: '/images/search.svg',
    };
  },
  methods: {
    textChanged() {
      this.$emit('filter', this.text);
    },
    clearSearch() {
      this.resetText();
      this.textChanged();
    },
    resetText() {
      this.text = '';
    },
  },
});
