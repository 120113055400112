




import Vue from 'vue';
import DeleteButton from '@/components/DetailedViewPopup/ActionButton.vue';
import Notification from '@/model/notification';
import Tool from '@/model/tool';

export default Vue.extend({
  name: 'DeleteTool',
  components: {
    DeleteButton,
  },
  props: {
    tool: { type: Object as () => Tool },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteTool() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.tool.delete', {
              name: this.tool.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('toolStorage/delete', this.tool)
              .then(() => this.$emit('delete'))
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
