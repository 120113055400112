import { render, staticRenderFns } from "./CategoryExpansionPanel.vue?vue&type=template&id=2e2ee7c5&scoped=true&"
import script from "./CategoryExpansionPanel.vue?vue&type=script&lang=ts&"
export * from "./CategoryExpansionPanel.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryExpansionPanel.vue?vue&type=style&index=0&id=2e2ee7c5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2ee7c5",
  null
  
)

export default component.exports