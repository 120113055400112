var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"date-picker-container",attrs:{"nudge-right":20,"transition":"scale-transition","disabled":_vm.isDisabled,"close-on-content-click":false,"min-width":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDatePicker = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.task || _vm.isDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex row m-0 align-center",class:{ editing: _vm.isEditing }},'div',attrs,false),Object.assign({}, onDatePicker, onTooltip)),[_c('v-text-field',{staticClass:"date",class:{
              'task-date-picker': _vm.task,
              'disabled-date': _vm.isDisabled,
              'filter-date': _vm.isFilter,
              'detailed-view': _vm.isEditing,
            },attrs:{"value":_vm.displayValue,"readonly":"","hide-details":"auto","flat":"","solo":""}}),((!_vm.requirement || _vm.isEditing) && !_vm.isDocument)?_c('v-img',{staticClass:"calendar",class:{ 'filter-icon': _vm.isFilter },attrs:{"src":_vm.calendar}}):_vm._e()],1)]}}],null,true)},[(_vm.task)?_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.task.date.' + (_vm.task.endDate ? 'modify' : 'add'))))]):_vm._e()])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"hu","first-day-of-week":"1","landscape":"","no-title":"","min":_vm.task ? _vm.requirement.addition : !_vm.addition && _vm.startDate ? _vm.startDate : '',"max":_vm.task ? _vm.requirement.deadline : _vm.addition && _vm.endDate ? _vm.endDate : ''},on:{"change":_vm.selectDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[(_vm.clearable)?_c('div',{staticClass:"w-100"},[_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDate}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.task.date.clear'))+" ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }