




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import { ToolCategory } from '@/model/tool-category';

export default Vue.extend({
  name: 'DeleteCategory',
  components: {
    DeleteButton,
  },
  props: {
    category: Object as () => ToolCategory,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteCategory() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            `${this.$t('basic-information.category.delete', {
              name: this.category.name,
            })} ${
              this.category.items && this.category.items.length
                ? this.$t('basic-information.category.items-to-uncategorized')
                : ''
            }`.toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store.dispatch('toolCategoryStorage/delete', this.category).finally(() => {
              this.loading = false;
              this.$emit('deleteFinished');
            });
          }
        });
    },
  },
});
