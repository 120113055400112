
















import Vue from 'vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import { DangerRate } from '@/model/enums/danger-rate';
import { TranslateResult } from 'vue-i18n';
import { mapGetters } from 'vuex';
import { ToolFilterOptions } from '@/store/modules/tool-filter-storage';
import { i18n } from '@/i18n';

interface Data {
  name: string | TranslateResult;
  value: DangerRate;
}

const checkboxValues: Data[] = [
  {
    name: i18n.t('basic-information.tool.danger-rate-low'),
    value: DangerRate.LOW,
  },
  {
    name: i18n.t('basic-information.tool.danger-rate-medium'),
    value: DangerRate.MEDIUM,
  },
  {
    name: i18n.t('basic-information.tool.danger-rate-high'),
    value: DangerRate.HIGH,
  },
];

export default Vue.extend({
  components: {
    Checkbox,
  },
  data() {
    return {
      checkboxValues,
    };
  },
  methods: {
    toggleDangerRate(dangerRate: DangerRate) {
      this.$store.commit('filterStorage/toolFilterStorage/toggleDangerRate', dangerRate);
      this.$emit('filterChanged');
    },
    isChecked(dangerRate: DangerRate): boolean {
      return (this.getToolFilterOptions as ToolFilterOptions).dangerRates.includes(dangerRate);
    },
  },
  computed: {
    ...mapGetters({
      getToolFilterOptions: 'filterStorage/toolFilterStorage/getToolFilterOptions',
    }),
  },
});
