
































































import Vue from 'vue';
import TaskDeadlineRequest from '@/model/request/task-deadline-request';
import Task from '@/model/task';
import Requirement from '@/model/requirement';

export default Vue.extend({
  name: 'DatePicker',
  model: {
    prop: 'value',
    event: 'changeDate',
  },
  props: {
    requirement: Object as () => Requirement,
    task: Object as () => Task,
    isDisabled: Boolean,
    value: String,
    addition: { type: Boolean, default: false },
    isFilter: { type: Boolean, default: false },
    isDocument: { type: Boolean, default: false },
    submitted: Boolean,
    startDate: String,
    endDate: String,
    isEditing: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  mounted() {
    if (!this.isFilter && this.addition) {
      this.date = (this as any).currentDate;
    }
  },
  computed: {
    displayValue(): string {
      return this.value ? this.value : (this as any).formatDate(this.date);
    },
  },
  data() {
    return {
      date: '',
      menu: false,
      calendar: '/images/clock-calendar.svg',
    };
  },
  watch: {
    submitted() {
      if (this.submitted && this.addition && !this.date) {
        this.date = (this as any).currentDate;
        this.$emit('changeDate', this.date);
      }
    },
  },
  methods: {
    open() {
      this.menu = true;
    },
    resetDate() {
      if (!this.isFilter && this.addition) {
        this.date = (this as any).currentDate;
      } else {
        this.date = '';
      }
      this.selectDate();
    },
    selectDate() {
      if (this.task) {
        this.$store.dispatch('requirementTaskStorage/setTaskDeadline', {
          taskDeadlineRequest: new TaskDeadlineRequest(this.date),
          taskId: this.task.id,
          requirement: this.requirement,
        });
      } else {
        this.$emit('changeDate', this.date);
      }
      this.menu = false;
    },
  },
});
