




















import Vue from 'vue';
import DatePicker from '@/components/InputField/DatePicker.vue';

export default Vue.extend({
  name: 'DateInput',
  components: {
    DatePicker,
  },
  props: {
    label: String,
    addition: Boolean,
    loading: Boolean,
    submitted: Boolean,
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      selectedDate: '',
    };
  },
  methods: {
    resetDate() {
      (this.$refs.datePicker as any).resetDate();
    },
    onDateChange(newDate: string) {
      this.selectedDate = newDate;
      this.$emit('dateModification', this.selectedDate, this.addition);
    },
  },
});
