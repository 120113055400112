









import RequirementType from '@/model/requirement-type';
import TypeIcon from '@/components/Dashboard/Table/TableColumns/Type/TypeIcon.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'TypeListItem',
  components: {
    TypeIcon,
  },
  props: {
    type: Object as () => RequirementType,
    selected: Boolean,
  },
});
