import { Prescriber } from '@/model/enums/prescriber';
import RequirementFilterRequest from '@/model/request/filter/requirement-filter-request';
import PeriodicOption from '@/model/periodic-option';

export default class RequirementRequest {
  public id: number | null;
  public title: string;
  public addition: string;
  public deadline: string;
  public typeId: number | null;
  public documentStoreVisible: boolean;
  public description: string;
  public prescribers: Prescriber[];
  public periodicOption: PeriodicOption | null;
  public category: string;
  public requirement: boolean;
  public mainId?: number;
  public filter?: RequirementFilterRequest;

  constructor(
    id: number | null,
    title: string,
    addition: string,
    deadline: string,
    typeId: number | null,
    documentStoreVisible: boolean,
    description: string,
    prescribers: Prescriber[],
    periodicOption: PeriodicOption | null,
    category: string,
    requirement: boolean,
    mainId?: number,
    filter?: RequirementFilterRequest,
  ) {
    this.id = id;
    this.title = title;
    this.addition = addition;
    this.deadline = deadline;
    this.typeId = typeId;
    this.documentStoreVisible = documentStoreVisible;
    this.description = description;
    this.prescribers = prescribers;
    this.periodicOption = periodicOption;
    this.category = category;
    this.requirement = requirement;
    this.mainId = mainId;
    this.filter = filter;
  }
}
