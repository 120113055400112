












































































































import Vue from 'vue';
import ToolRow from '@/components/BasicInformation/Tools/ToolRow.vue';
import ToolDetailsForm from '@/components/BasicInformation/Tools/ToolDetailsForm.vue';
import DeleteCategory from '@/components/BasicInformation/Tools/Category/DeleteCategory.vue';
import AddButton from '@/components/Button/AddButton.vue';
import AutoIdToggle from '@/components/InputField/Toggle.vue';
import ToolDetailedView from '@/components/BasicInformation/Tools/ToolDetailedView.vue';
import HeaderBase from '@/components/Header/HeaderBase.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import CategoryForm from '@/components/BasicInformation/CategoryForm.vue';
import CategoryExpansionPanel from '@/components/BasicInformation/CategoryExpansionPanel.vue';
import { mapGetters } from 'vuex';
import Tool from '@/model/tool';
import AutoIncrementingIdRequest from '@/model/request/auto-incrementing-id-request';
import BasicInformationRequest from '@/model/request/basic-information-request';
import { ToolCategory } from '@/model/tool-category';
import ToolFilter from '@/components/BasicInformation/Tools/ToolFilter.vue';
import TodoTasksForm from '@/components/BasicInformation/People/TodoTasksForm.vue';

export default Vue.extend({
  name: 'BasicInformationTools',
  components: {
    AddButton,
    AutoIdToggle,
    Checkbox,
    DeleteCategory,
    HeaderBase,
    ToolDetailsForm,
    TodoTasksForm,
    ToolDetailedView,
    ToolFilter,
    ToolRow,
    CategoryForm,
    CategoryExpansionPanel,
  },
  data() {
    return {
      loading: false,
      headerImage: '/images/tool.svg',
      selectedTool: {} as Tool,
      selectedTools: [] as Tool[],
    };
  },
  mounted() {
    (this as any).fetch();
    this.$emit('setFilter', this.searchText);
    this.$store.dispatch('configurationStorage/getToolAutoIncrementingId');
  },
  computed: {
    ...mapGetters({
      tools: 'toolStorage/getPageableOperationalUnitTools',
      uncategorizedTools: 'toolStorage/getUncategorizedTools',
      categories: 'toolCategoryStorage/getCategories',
      filtering: 'filterStorage/toolFilterStorage/isFiltering',
      searchText: 'filterStorage/getBasicInformationSearchText',
      isAutoIncrementing: 'configurationStorage/isToolAutoIncrementingId',
      isAdmin: 'currentUserStorage/isAdmin',
    }),
    displayableCategories(): ToolCategory[] {
      return this.searchText.length > 0 || this.filtering
        ? this.categories.filter((category: ToolCategory) => category.items.length > 0)
        : this.categories;
    },
    selectedToolIds(): number[] {
      return this.selectedTools.map((e) => e.id);
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('toolStorage/getCategorizedTools', this.$route.params?.id)
        .finally(() => (this.loading = false));
    },
    textFiltering() {
      this.fetch();
    },
    openDetailedView(item: Tool) {
      this.selectedTool = item;
      this.$nextTick(() => {
        (this.$refs.detailedView as any).open();
      });
    },
    closeDetailedView() {
      this.selectedTool = {} as Tool;
    },
    update(modifiedTool: Tool) {
      this.selectedTool = modifiedTool;
    },
    changeAutoId(value: boolean) {
      this.$store.dispatch('configurationStorage/setToolAutoIncrementingId', new AutoIncrementingIdRequest(value));
    },
    addCategory(name: string) {
      this.$store
        .dispatch('toolCategoryStorage/add', new BasicInformationRequest(name))
        .then((data) => {
          if (data) {
            (this.$refs.categoryAddition as any).closeDialog();
          }
        })
        .finally(() => {
          (this.$refs.categoryAddition as any).resetLoading();
        });
    },
    modifyCategory(name: string, categoryId: number) {
      const form = (this.$refs[`${categoryId}-expansionPanel`] as any)[0];
      this.$store
        .dispatch('toolCategoryStorage/modify', new BasicInformationRequest(name, categoryId))
        .then((data) => {
          if (data) {
            form.closeDialog();
          }
        })
        .finally(() => {
          form.resetDialogLoading();
        });
    },
    toggleToolSelection(tool: Tool) {
      const index = this.selectedTools.findIndex((e) => e.id === tool.id);

      if (index >= 0) {
        this.selectedTools.splice(index, 1);
      } else {
        this.selectedTools.push(tool);
      }
    },
    onTaskAddClick() {
      (this.$refs.taskAdditionDialog as any).openDialog(
        this.selectedTools
          .map((tool) => tool.name)
          .sort()
          .join('\n'),
      );
    },
    onTodoCreated() {
      this.selectedTools.splice(0);
    },
  },
});
