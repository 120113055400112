












import Vue from 'vue';
import { periodicOptions } from '@/constants/periodic-options';
import PeriodSelect from '@/components/InputField/PeriodSelect.vue';
import PeriodicOption from '@/model/periodic-option';
import Checkbox from '@/components/InputField/Checkbox.vue';

export default Vue.extend({
  name: 'Periodic',
  components: {
    Checkbox,
    PeriodSelect,
  },
  props: {
    active: { type: Boolean, default: false },
    submitted: { type: Boolean, default: false },
    selectedPeriod: { type: Object as () => PeriodicOption },
    durationError: { type: String, default: '' },
  },
  watch: {
    selected() {
      this.$emit('update:selectedPeriod', this.selected);
    },
  },
  computed: {
    isCustom(): boolean {
      return !this.selected?.term;
    },
  },
  data() {
    return {
      periodicOptions,
      isActive: this.active,
      selected: this.active && this.selectedPeriod?.term ? this.selectedPeriod : periodicOptions[0].value,
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.$emit('update:active', this.isActive);
      this.$emit('update:selectedPeriod', this.selected);
      if (!this.isActive) {
        this.resetSelect();
      }
    },
    reset() {
      this.isActive = false;
      this.resetSelect();
    },
    resetSelect() {
      this.selected = this.periodicOptions[0].value;
    },
  },
});
