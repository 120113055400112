import { Prescriber } from '@/model/enums/prescriber';

export const prescribers = [
  {
    type: Prescriber.EU_HU_STANDARD,
    icon: '/images/eu.svg',
  },
  {
    type: Prescriber.HU_REGULATION,
    icon: '/images/hu.svg',
  },
  {
    type: Prescriber.LEADERSHIP_ORDER,
    icon: '/images/leadership.svg',
  },
  {
    type: Prescriber.INNER_REGULATION,
    icon: '/images/inner.svg',
  },
  {
    type: Prescriber.HOMELAND_REQUIREMENT,
    icon: '/images/homeland.svg',
  },
  {
    type: Prescriber.PROCEDURAL_INSTRUCTION,
    icon: '/images/procedural.svg',
  },
  {
    type: Prescriber.OTHER,
    icon: '/images/other.svg',
  },
];
