

























































































































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import FormBase from '@/components/Form/Form.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import TextField from '@/components/InputField/TextField.vue';
import Select from '@/components/InputField/Select.vue';
import FileUpload from '@/components/InputField/FileUpload.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Document from '@/model/document';
import ToolFilterRequest from '@/model/request/filter/tool-filter-request';
import { ToolRequest } from '@/model/request/tool-request';
import { mapGetters } from 'vuex';
import { DangerRate } from '@/model/enums/danger-rate';
import { ToolCategory } from '@/model/tool-category';

export default Vue.extend({
  name: 'ToolDetailsForm',
  components: {
    FormBase,
    FormField,
    Textarea,
    TextField,
    Select,
    FileUpload,
    Checkbox,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        name: '',
        identifier: '',
        category: {} as ToolCategory,
        dangerRate: '' as DangerRate,
        comment: '',
        locationCode: '',
        active: false,
        operationalDocuments: [] as Document[],
      },
      attachments: new Array<File>(),
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(200),
      },
      identifier: {
        required,
        maxLength: maxLength(200),
      },
      dangerRate: {
        required,
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
      comment: {
        maxLength: maxLength(5000),
      },
    },
  },
  computed: {
    ...mapGetters({
      searchText: 'filterStorage/getBasicInformationSearchText',
      filterOptions: 'filterStorage/toolFilterStorage/getToolFilterOptions',
      categories: 'toolCategoryStorage/getToolCategoryOptions',
      isAutoIncrementing: 'configurationStorage/isToolAutoIncrementingId',
    }),
    dangerRates(): DangerRate[] {
      return Object.keys(DangerRate) as DangerRate[];
    },
  },
  methods: {
    resetForm() {
      this.form.name = '';
      this.form.identifier = '';
      this.form.category = {} as ToolCategory;
      this.form.active = false;
      this.form.dangerRate = '' as DangerRate;
      this.form.comment = '';
      this.form.locationCode = '';
      this.form.operationalDocuments = [] as Document[];
      this.attachments = [] as File[];
      this.submitted = false;
    },
    openDialog() {
      this.form.dangerRate = this.dangerRates[0];
      (this as any).loadCategories();
      if (this.isAutoIncrementing) {
        this.$store.dispatch('toolStorage/getNextId').then((data: string) => (this.form.identifier = data));
      }
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const formData = new FormData();
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'tool',
        new Blob(
          [
            JSON.stringify({
              ...this.form,
              operationalUnitId: parseInt(this.$route.params?.id, 10),
            } as ToolRequest),
          ],
          {
            type: 'application/json',
          },
        ),
      );
      const filterRequest = new ToolFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }
      formData.append(
        'filter',
        new Blob([JSON.stringify(filterRequest.dangerRatesToArray())], {
          type: 'application/json',
        }),
      );
      this.loading = true;
      this.$store
        .dispatch('toolStorage/save', formData)
        .then((data) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
