






import RequirementType from '@/model/requirement-type';
import Vue from 'vue';

export default Vue.extend({
  name: 'TypeIcon',
  props: {
    type: Object as () => RequirementType,
    table: Boolean,
    isDetailedView: Boolean,
  },
});
