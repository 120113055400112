var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center",class:{ 'start-aligned-container': _vm.isAddition || _vm.isDetailedView }},_vm._l((_vm.prescriberList),function(prescriber,i){return _c('div',{key:i},[_c('div',{staticClass:"icon-container",class:{
        'grey-image': !_vm.containsPrescriber(prescriber.type),
        'bigger-container': _vm.isAddition || _vm.isDetailedView,
        'filter-container': _vm.isFilter,
        'selectable': _vm.selectable,
        'disabled-icon': _vm.loading,
      },attrs:{"id":_vm.requirement
          ? _vm.isDetailedView
            ? ("detailed" + (_vm.requirement.id) + i)
            : _vm.requirement.id.toString() + i
          : ("addition" + _vm.category + i)},on:{"click":function($event){return _vm.modify(prescriber.type)}}},[_c('v-img',{staticClass:"prescriber-icon",class:{ 'bigger-icon': _vm.isAddition || _vm.isDetailedView, 'filter-icon': _vm.isFilter },attrs:{"src":prescriber.icon}})],1),_c('b-tooltip',{attrs:{"target":_vm.requirement
          ? _vm.isDetailedView
            ? ("detailed" + (_vm.requirement.id) + i)
            : _vm.requirement.id.toString() + i
          : ("addition" + _vm.category + i),"triggers":"hover","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.prescriber.' + prescriber.type.toLowerCase()))+" ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }