






import Vue from 'vue';
export default Vue.extend({
  name: 'AddButton',
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      plus: '/images/plus.svg',
    };
  },
});
