import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('basic-information.tool.details'),
    icon: '/images/info.svg',
    componentName: 'ToolDetails',
  },
  {
    name: i18n.t('basic-information.tool.documents'),
    icon: '/images/documents.svg',
    componentName: 'ToolDocuments',
  },
];
