










































import Vue from 'vue';
import TableBase from '@/components/Table/Table.vue';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import Tool from '@/model/tool';
import Person from '@/model/person';
import Business from '@/model/business';

export default Vue.extend({
  name: 'UnitTable',
  components: {
    TableBase,
  },
  props: {
    data: { type: Object as () => Pageable<any>, required: true },
    rawData: { type: Array as () => any[], default: () => [] },
    loading: { type: Boolean, default: false },
    headerImage: { type: String, default: '' },
    headerTitle: { type: String, default: '' },
    itemIcon: { type: String, validator: (value: string) => ['person', 'tool'].includes(value) },
    hasHeader: { type: Boolean, default: true },
  },
  data() {
    return {
      tableFields: [
        {
          key: 'name',
          visible: true,
          thStyle: 'width: 65%',
        },
        {
          key: 'buttons',
          label: '',
          visible: this.itemIcon,
          thStyle: 'width: 35%',
        },
      ],
    };
  },
  computed: {
    visibleColumns(): any[] {
      return this.tableFields.filter((item) => item.visible);
    },
  },
  methods: {
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.$emit('pageOptionsChanged', newPageOptions);
    },
    showDetails(item: Person | Tool | Business) {
      this.$emit('showDetails', item);
    },
  },
});
