import { render, staticRenderFns } from "./DangerRateFilter.vue?vue&type=template&id=10be5ee6&scoped=true&"
import script from "./DangerRateFilter.vue?vue&type=script&lang=ts&"
export * from "./DangerRateFilter.vue?vue&type=script&lang=ts&"
import style0 from "./DangerRateFilter.vue?vue&type=style&index=0&id=10be5ee6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10be5ee6",
  null
  
)

export default component.exports