






















import Vue from 'vue';
import CategoryForm from '@/components/BasicInformation/CategoryForm.vue';
import { PersonCategory } from '@/model/person-category';
import { ToolCategory } from '@/model/tool-category';

export default Vue.extend({
  name: 'CategoryExpansionPanel',
  components: {
    CategoryForm,
  },
  props: {
    category: { type: Object as () => PersonCategory | ToolCategory },
  },
  data() {
    return {
      loading: false,
      panel: undefined,
    };
  },
  methods: {
    closeDialog() {
      (this.$refs.categoryModification as any).closeDialog();
    },
    resetDialogLoading() {
      (this.$refs.categoryModification as any).resetLoading();
    },
    modifyCategory(name: string) {
      this.$emit('modify', name, this.category.id);
    },
  },
});
