















import Vue from 'vue';
import RadioButton from '@/components/InputField/RadioButton.vue';
import { mapGetters } from 'vuex';
import { ToolFilterOptions } from '@/store/modules/tool-filter-storage';
import { i18n } from '@/i18n';
import { TranslateResult } from 'vue-i18n';

interface SelectableOption {
  title: TranslateResult;
  value: any;
}

const items: SelectableOption[] = [
  {
    title: i18n.t('basic-information.people.condition-filter.all'),
    value: undefined,
  },
  {
    title: i18n.t('basic-information.people.condition-filter.active'),
    value: true,
  },
  {
    title: i18n.t('basic-information.people.condition-filter.inactive'),
    value: false,
  },
];

export default Vue.extend({
  name: 'StatusFilter',
  components: {
    RadioButton,
  },
  data() {
    return {
      selectableItems: items,
      selected: undefined as boolean | undefined,
    };
  },
  mounted() {
    this.selected = (this.filterOptions as ToolFilterOptions).active;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/toolFilterStorage/getToolFilterOptions' }),
  },
  methods: {
    onChange(value: boolean | undefined) {
      this.selected = value;
      this.$store.commit('filterStorage/toolFilterStorage/setActive', value);
      this.$emit('filterChanged');
    },
  },
});
