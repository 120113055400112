




















































import Vue from 'vue';
import { periodicOptions } from '@/constants/periodic-options';
import { periodicTerms } from '@/constants/periodic-terms';
import PeriodicOption from '@/model/periodic-option';
import { PeriodicTerm } from '@/model/enums/periodic-term';
import { requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'PeriodSelect',
  model: {
    prop: 'selectedPeriod',
    event: 'modifyPeriodic',
  },
  props: {
    selectedPeriod: { type: Object as () => PeriodicOption },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    selectedPeriod() {
      this.setSelected();
    },
  },
  computed: {
    isCustom(): boolean {
      return this.selected === this.periodicOptions[this.periodicOptions.length - 1].value;
    },
  },
  created() {
    this.setSelected();
  },
  data() {
    return {
      periodicOptions,
      periodicTerms,
      selected: this.selectedPeriod?.term ? this.selectedPeriod : this.clearable ? null : periodicOptions[0].value,
      customPeriod: new PeriodicOption(1, periodicTerms[0].value),
      minValue: 1,
      maxValue: 1000,
    };
  },
  validations: {
    customPeriod: {
      frequencyNumber: {
        required: requiredIf(function(this: any) {
          return this.isCustom;
        }),
        minValue: minValue(1),
        maxValue: maxValue(1000),
      },
      term: {
        required: requiredIf(function(this: any) {
          return this.isCustom;
        }),
      },
    },
  },
  methods: {
    select(newValue: PeriodicOption, cleared: boolean) {
      this.selected = newValue;
      (this.$refs.periodicSelect as any).blur();
      this.$emit('modifyPeriodic', this.selected.term ? this.selected : cleared ? null : this.customPeriod);
    },
    frequencyChange(value: number) {
      this.$nextTick(() => {
        this.customPeriod.frequencyNumber =
          !value || value < 0 ? this.minValue : value > this.maxValue ? this.maxValue : value;
        (this.$refs.customSelect as any).blur();
        this.$emit('modifyPeriodic', this.customPeriod);
      });
    },
    termSelect(newTerm: PeriodicTerm) {
      this.customPeriod.term = newTerm;
      (this.$refs.customSelect as any).blur();
      this.$emit('modifyPeriodic', this.customPeriod);
    },
    reset() {
      this.selected = this.periodicOptions[0].value;
      this.customPeriod = new PeriodicOption(1, this.periodicTerms[0].value);
    },
    clear() {
      this.select({} as PeriodicOption, true);
    },
    setSelected() {
      if (this.selectedPeriod?.term) {
        if (
          this.periodicOptions.find(
            (option) =>
              option.value.frequencyNumber === this.selectedPeriod.frequencyNumber &&
              option.value.term === this.selectedPeriod.term,
          )
        ) {
          this.selected = this.selectedPeriod;
        } else {
          this.selected = this.periodicOptions[this.periodicOptions.length - 1].value;
          this.customPeriod = this.selectedPeriod;
        }
      } else if (this.clearable) {
        this.selected = {} as PeriodicOption;
      } else {
        this.selected = this.periodicOptions[0].value;
      }
    },
  },
});
