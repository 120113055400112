






































































































import Vue from 'vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import Select from '@/components/InputField/Select.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Tool from '@/model/tool';
import { required, maxLength } from 'vuelidate/lib/validators';
import { DangerRate } from '@/model/enums/danger-rate';
import { ToolRequest } from '@/model/request/tool-request';
import { mapGetters } from 'vuex';
import ToolFilterRequest from '@/model/request/filter/tool-filter-request';

export default Vue.extend({
  name: 'ToolDetails',
  components: {
    FormField,
    TextField,
    Textarea,
    Select,
    Checkbox,
  },
  props: {
    tool: { type: Object as () => Tool },
    editing: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    submitted: { type: Boolean, default: false },
    editedName: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      form: {
        name: this.editedName,
        identifier: this.tool.identifier,
        category: this.tool.category,
        dangerRate: this.tool.dangerRate,
        comment: this.tool.comment,
        locationCode: this.tool.locationCode,
        active: this.tool.active,
      },
    };
  },
  watch: {
    editedName() {
      this.form.name = this.editedName;
    },
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(200),
      },
      identifier: {
        required,
        maxLength: maxLength(200),
      },
      dangerRate: {
        required,
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
      comment: {
        maxLength: maxLength(5000),
      },
    },
  },
  computed: {
    ...mapGetters({
      categories: 'toolCategoryStorage/getToolCategoryOptions',
      searchText: 'filterStorage/getBasicInformationSearchText',
      filterOptions: 'filterStorage/toolFilterStorage/getToolFilterOptions',
    }),
    dangerRates(): DangerRate[] {
      return Object.keys(DangerRate) as DangerRate[];
    },
  },
  methods: {
    reset() {
      this.form.name = this.editedName;
      this.form.identifier = this.tool.identifier;
      this.form.category = this.tool.category;
      this.form.dangerRate = this.tool.dangerRate;
      this.form.comment = this.tool.comment;
      this.form.locationCode = this.tool.locationCode;
      this.form.active = this.tool.active;
      this.$emit('setSaving', false);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('setSaving', true);

      const filterRequest = new ToolFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }

      this.$store
        .dispatch('toolStorage/modify', {
          request: {
            id: this.tool?.id,
            filter: filterRequest.dangerRatesToArray(),
            ...this.form,
          } as ToolRequest,
          prevCategory: this.tool.category,
        })
        .then((data) => {
          if (data) {
            this.$emit('update', data);
          }
        })
        .finally(() => {
          this.$emit('setSaving', false);
        });
    },
  },
});
