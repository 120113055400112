import moment from 'moment';
import { FILTER_DATE_FORMAT } from '@/constants/date-format';
import { PeriodicTerm } from '@/model/enums/periodic-term';
import PeriodicOption from '@/model/periodic-option';
import { Prescriber } from '@/model/enums/prescriber';
import { notEmptyList } from '@/validators/common';

function addPeriodToDate(startDate: string, unit: number | null, term: moment.unitOfTime.DurationConstructor) {
  return unit
    ? moment(startDate)
        .add(unit, term)
        .format(FILTER_DATE_FORMAT)
    : moment(startDate).format(FILTER_DATE_FORMAT);
}

export const shortPeriod = (
  periodOption: PeriodicOption,
  submitted: boolean,
  startDate: string,
  endDate: string,
  active: boolean,
): boolean => {
  let isValid = true;
  if (submitted && startDate && endDate && active) {
    let nextPeriod = '';
    switch (periodOption.term) {
      case PeriodicTerm.DAY:
        nextPeriod = addPeriodToDate(startDate, periodOption.frequencyNumber, 'days');
        break;
      case PeriodicTerm.WEEK:
        nextPeriod = addPeriodToDate(startDate, periodOption.frequencyNumber, 'weeks');
        break;
      case PeriodicTerm.MONTH:
        nextPeriod = addPeriodToDate(startDate, periodOption.frequencyNumber, 'months');
        break;
      case PeriodicTerm.YEAR:
        nextPeriod = addPeriodToDate(startDate, periodOption.frequencyNumber, 'years');
        break;
    }
    isValid = new Date(nextPeriod) > new Date(endDate);
  }
  return isValid;
};

export const prescriberList = (list: Prescriber[]) => {
  return notEmptyList(list);
};

export const endBeforeStart = (startDate: string, endDate: string): boolean => {
  return !endDate ? true : (startDate ? new Date(startDate) : new Date()) <= new Date(endDate);
};
