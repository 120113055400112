












































import Vue from 'vue';
import { Prescriber } from '@/model/enums/prescriber';
import Requirement from '@/model/requirement';
import { prescribers } from '@/constants/prescribers';

export default Vue.extend({
  name: 'Prescribers',
  props: {
    requirement: Object as () => Requirement,
    isAddition: { type: Boolean, default: false },
    isFilter: { type: Boolean, default: false },
    isDetailedView: { type: Boolean, default: false },
    selected: Array as () => Prescriber[],
    loading: { type: Boolean, default: false },
    category: String,
    selectable: { type: Boolean, default: false },
  },
  data() {
    return {
      prescriberList: prescribers,
    };
  },
  methods: {
    containsPrescriber(prescriber: Prescriber): boolean {
      return this.isAddition || this.isFilter
        ? this.selected?.includes(prescriber)
        : this.requirement?.prescribers?.includes(prescriber);
    },
    modify(modifiedPrescriber: Prescriber) {
      if ((this.isAddition || this.selectable) && !this.loading) {
        this.$emit('modifyPrescriber', modifiedPrescriber);
      }
    },
  },
});
