



















import Vue from 'vue';
import UnitTable from '@/components/BasicInformation/UnitTable.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Tool from '@/model/tool';

export default Vue.extend({
  name: 'ToolRow',
  components: {
    UnitTable,
    Checkbox,
  },
  props: {
    data: { type: Array as () => Tool[] },
    loading: { type: Boolean, default: false },
    selectedToolIds: { type: Array as () => number[] },
  },
  methods: {
    showDetails(item: Tool) {
      this.$emit('showDetails', item);
    },
    isChecked(item: Tool) {
      return this.selectedToolIds.includes(item.id);
    },
    onSelect(item: Tool) {
      this.$emit('toolSelected', item);
    },
  },
});
