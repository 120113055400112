
























import RequirementType from '@/model/requirement-type';
import TypeListItem from '@/components/Dashboard/RequirementAddition/TypeListItem.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'TypeSelection',
  components: {
    TypeListItem,
  },
  model: {
    prop: 'selected',
    event: 'modifyType',
  },
  props: {
    submitted: Boolean,
    loading: Boolean,
    hideDetails: { type: Boolean, default: false },
    selected: { type: Object as () => RequirementType, default: Object as () => RequirementType },
    isFilter: { type: Boolean, default: false },
  },
  mounted() {
    this.$store.dispatch('requirementStorage/getTypes');
  },
  data() {
    return {
      selectedType: this.selected,
    };
  },
  validations: {
    selectedType: {
      required,
    },
  },
  computed: {
    ...mapGetters({ types: 'requirementStorage/getTypes' }),
    requirementTypeError(): string {
      return this.submitted && !(this.$v.selectedType as any).required
        ? this.$t('dashboard.requirement-addition.type-required').toString()
        : '';
    },
    options(): RequirementType[] {
      const options = this.types;
      if (this.isFilter) {
        options.unshift({} as RequirementType);
      } else if (this.selected?.id && !options.find((t: RequirementType) => t.id === this.selected.id)) {
        options.push(this.selected);
      }
      return options;
    },
  },
  methods: {
    select(item: RequirementType) {
      this.selectedType = item;
      (this.$refs.typeSelect as any).blur();
      this.$emit('modifyType', this.selectedType);
    },
    reset() {
      this.selectedType = {} as RequirementType;
    },
  },
});
