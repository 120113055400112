
















import Option from '@/model/option';
import Vue from 'vue';

export default Vue.extend({
  name: 'RadioButton',
  props: {
    option: { type: Object as () => Option },
    selected: { type: [Boolean, String], required: false },
    disabled: { type: Boolean, default: false },
  },
});
